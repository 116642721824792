function daysInThisMonth() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
}

function updatePricingTable() {
    const therapists = $(".add_therapist_wrapper");
    let monthly = 0;
    let yearly = 0;
    for (let i = therapists.length - 1; i >= 0; i -= 1) {
        if ($(therapists[i]).find("#subType option:selected").val() === "monthly") {
            monthly += 1;
        } else {
            yearly += 1;
        }
    }

    const date = new Date();
    const time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    const days = time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
    const percentageLeft = days / daysInThisMonth();

    const today = new Date();
    const cmas = new Date(today.getFullYear(), 11, 31);

    const oneDay = 1000 * 60 * 60 * 24;
    const daysLeft = Math.ceil((cmas.getTime() - today.getTime()) / oneDay);
    const percentageLeftYear = daysLeft / 365;

    const monthRemaining = window.month_prize * monthly * percentageLeft;
    const yearRemaining = window.year_prize * yearly * percentageLeftYear;

    $("#price-table-now-month-price").text(`€${Math.round(monthRemaining * 100) / 100}`);
    $("#price-table-now-year-price").text(`€${Math.round(yearRemaining * 100) / 100}`);

    const totalPrice =
        Math.round(
            (window.year_prize * yearly * percentageLeftYear + window.month_prize * monthly * percentageLeft) * 121
        ) / 100;

    $("#price-table-now-total-price").text(`€${Math.max(totalPrice, 0.02)}`);

    // Calculate the maximum discount.
    if (!window.discountUsed) window.discountUsed = 0;

    let price = window.discountMonthly ? monthRemaining : yearRemaining;
    price =
        window.discount * price + window.discountUsed > window.discountMaximum
            ? price -
              (window.discountMaximum - window.discountUsed > 0 ? window.discountMaximum - window.discountUsed : 0)
            : price * (1 - window.discount);

    price += window.discountMonthly ? yearRemaining : monthRemaining;
    price = Math.round(price * 121) / 100;

    $("#price-table-now-total-price-discount").text(`€${Math.max(price, 0.02)}`);

    $("#price-table-month-month-price").text(`€${Math.round(window.month_prize * monthly * 100) / 100}`);
    $("#price-table-month-year-price").text(`€${Math.round(window.year_prize * yearly * 100) / 100}`);

    $("#price-table-now-year-amount").text(
        window.jsLocalArguments(trans.forms["therapist_yearly"], { i: yearly, amount: window.year_prize })
    );
    $("#price-table-month-year-amount").text(
        window.jsLocalArguments(trans.forms["therapist_yearly"], { i: yearly, amount: window.year_prize })
    );
    $("#price-table-now-month-amount").text(
        window.jsLocalArguments(trans.forms["therapist_monthly"], { i: monthly, amount: window.month_prize })
    );
    $("#price-table-month-month-amount").text(
        window.jsLocalArguments(trans.forms["therapist_monthly"], { i: monthly, amount: window.month_prize })
    );
}
window.updatePricingTable = updatePricingTable;

/**
 * A function to add a new node form field to the form
 *
 * @param {string} - define whether the node is a 'therapist' or an 'address'.
 */
function addNode(node) {
    const buttonText = node === "address" ? trans.forms["remove-address"] : trans.forms["remove-therapist"];
    const buttonHelper = `<div class="d-flex"><button type="button" class="btn btn-primary btn-raised  \
        remove-button center-horizontally small-vertical-marg" onclick="deleteRow(this)">${buttonText}</button></div>`;
    if (window.globalHelperTherapistNode == null) {
        const tmp = $("#therapist_add_form_group").clone();
        tmp.children().append(buttonHelper);
        window.globalHelperTherapistNode = tmp.html();
    }
    $("#therapist_add_form_group").append(window.globalHelperTherapistNode);
}

/*
 * A function to add a new therapist form field to the form
 */
function addTherapistNode() {
    addNode("therapist");
    updatePricingTable();
}
window.addTherapistNode = addTherapistNode;

/* Deletes a therapist form field
 */
function deleteRow(e) {
    e.parentNode.parentNode.parentNode.removeChild(e.parentNode.parentNode);
    updatePricingTable();
}
window.deleteRow = deleteRow;

/*
 * When the DOM with all its elements are loaded.
 */
$(function () {
    window.globalHelperTherapistNode = null;
    updatePricingTable();
});
